<template>
  <div v-if="!item.hide" :class="`${newsGroupOpened(item.id) ? 'bg-gray-800/50' : ''}`">
    <div v-if="item.checkpoint" class="mb-8 mt-3 flex w-full items-center border-b-2 border-blue-900">
      <div class="-mb-3 ml-auto mr-auto rounded-full bg-blue-900 px-3 py-1 text-xs text-blue-100">
        Left off at {{ minimalTimeWithinToday(item.checkpoint) }}
      </div>
    </div>
    <div
      class="relative flex"
      @mouseover="mouseEnter"
      @mouseleave="mouseLeave"
      :class="{ 'border-l-2 border-blue-400 bg-blue-900/[0.15]': recentNewsItem }"
    >
      <div :class="`flex w-20 flex-col items-end justify-center px-1`">
        <NewsFeedItemActions
          v-if="hovering"
          :is-starred="item.is_starred_by_my_team"
          @delete="adminDelete"
          @share-news-item="share"
          @toggle-starred="toggleStar"
          :can-delete="canEditOrDelete"
          :news-item-id="item.id"
          class="mb-1"
        />
        <span :class="`flex items-center space-x-0.5 text-right text-xs text-secondary `">
          <div>{{ minimalTimeWithinToday(item.timestamp) }}</div>
        </span>
      </div>
      <div class="flex-1 px-4 py-2">
        <div class="flex flex-row gap-1">
          <span
            class="flex select-none items-center"
            @click="handleSourceClick"
            @mouseover="hoveringOverSource = true"
            @mouseleave="hoveringOverSource = false"
          >
            <BaseButton v-if="hoveringOverSource" type="blank" size="xxs" class="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3 text-red-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                  clip-rule="evenodd"
                />
              </svg>
            </BaseButton>
            <BaseTooltip>
              <template #target>
                <span :class="`text-xs ${hoveringOverSource ? 'cursor-pointer text-red-400' : 'text-gray-300'}`">
                  {{ sourceName }}
                </span>
              </template>
              <template #default v-if="item.sources.length > 1">
                {{ item.sources.map(o => o.name).join(', ') }}
              </template>
            </BaseTooltip>
          </span>
          <NewsFeedItemStarredLabel
            v-if="item.is_starred_by_my_team && item.starred_by_team_member"
            class="ml-2"
            :name="item.starred_by_team_member.email"
          />
        </div>
        <a v-bind="{ ...(!signalNews(item) && { href: item?.link }) }" target="_blank" class="flex items-center">
          <p
            class="mt-0.5 line-clamp-2 cursor-pointer text-sm font-medium"
            @click="signalNews(item) && showOnChainSidebar(item.link)"
            v-html="item.group_similar_news ? item.grouped_headline : item.translated_headline || item.headline"
          />
          <ArrowTopRightOnSquareIcon v-if="!signalNews(item)" class="ml-1 h-4 w-4 flex-shrink-0" />
        </a>

        <BaseButton
          v-if="item.similarNews"
          type="secondary"
          size="xs"
          class="mt-1 rounded-full"
          @press="toggleVisibility(item.id)"
        >
          <span class="mx-2 flex items-center">
            <span class="pr-0.5">
              {{ newsGroupOpened(item.id) ? 'Hide' : 'Show' }} {{ item.grouped.length }} Related Headlines
            </span>
            <ChevronDoubleDownIcon v-if="!newsGroupOpened(item.id)" class="h-3 w-3"></ChevronDoubleDownIcon>
            <ChevronDoubleUpIcon v-if="newsGroupOpened(item.id)" class="h-3 w-3"></ChevronDoubleUpIcon>
          </span>
        </BaseButton>
        <div class="mt-1 flex w-full justify-between text-xs text-secondary">
          <div class="flex flex-wrap">
            <NewsFeedItemTags :tags="item.tags" :item="item" @handle-tag-click="handleTagClick" />
          </div>
        </div>
      </div>
    </div>
    <div class="border border-gray-700 border-opacity-25" />
    <div v-if="newsGroupOpened(item.id)" class="pl-[4.1rem]">
      <VirtualScrollItemNews
        v-for="newItem in item.grouped"
        :key="newItem.id"
        :index="index"
        :is-focus-out="isFocusOut"
        :source="newItem"
        :from-labs="fromLabs"
        :is-grouped="true"
      />
    </div>
  </div>
</template>

<script setup>
import { copyToClipboard } from '@/composeables/helpers';
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon, ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid';
import NewsFeedItemTags from '@/components/news_feeds/NewsFeedItemTags.vue';
import NewsFeedItemStarredLabel from '@/components/news_feeds/NewsFeedItemStarredLabel.vue';
import NewsFeedItemActions from '@/components/news_feeds/NewsFeedItemActions.vue';
import { minimalTimeWithinToday } from '@/composeables/filters';
import { ref, onBeforeMount, inject, watch, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { router } from '@inertiajs/vue3';
import { signalNews } from '@/composeables/news';
import { showOnChainSidebar } from '@/composeables/news';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { DateTime } from 'luxon';

const $store = useStore();
const $http = useHttp();
const $emitter = useEmitter();
const instance = getCurrentInstance();
const $root = instance.root.proxy;
const $parent = instance.parent.proxy;

const props = defineProps({
  isFocusOut: { type: Boolean, default: () => false },
  index: { type: Number },
  source: { type: Object, default: () => {} },
  fromLabs: { type: Boolean, default: () => false },
  isGrouped: { type: Boolean, default: () => false },
  showGroupedNewsItems: { type: Array, default: () => [] },
  notChainFeed: { type: Boolean }
});

const hovering = ref(false);
const hoveringOverSource = ref(false);
const holdingShift = ref(false);
const interval = ref(0);
const item = ref({});

onBeforeMount(() => {
  item.value = props.source;
});

watch(
  () => props.source,
  () => {
    item.value = props.source;
  },
  { deep: true }
);

const canEditOrDelete = computed(() => {
  return $store.getters.roles.includes('tie_team') && !props.fromLabs;
});

const recentNewsItem = computed(() => {
  const now = DateTime.now();
  const minutes = now.diff(DateTime.fromISO(item.value.timestamp), 'minutes').minutes;
  return minutes < 5 ? true : false;
});

const companyTagGroups = computed(() => {
  return $store.state.companyTagGroups;
});

const sourceName = computed(() => {
  return item.value.sources.length > 1
    ? `${item.value.sources[0]?.name} and ${item.value.sources.length - 1} more`
    : item.value.sources[0]?.name;
});

const sortedTags = computed(() => {
  const sigdev = item.value.tags.filter(i => i.type === 'sigdev');
  const coins = item.value.tags.filter(i => i.type === 'coin');

  return sigdev.concat(coins);
});

watch(
  () => props.isFocusOut,
  () => {
    // In order to remove shift exclude option on page focus out/ page hide
    if (holdingShift.value) {
      holdingShift.value = !holdingShift.value;
    }
  }
);

function handleTagClick(tag, newsItem) {
  if (tag.tag_subgroup.name == 'Coins' && tag.coin) {
    $store.dispatch('enableCoinSidebar', { coin: tag.coin.uid, newsItem: newsItem });
  }

  if (companyTagGroups.value.includes(tag.tag_subgroup.name)) {
    const company = $store.state.companySlugs.find(x => x.tag == tag.id);
    if (company) {
      router.visit(`/company/${company.company_uid}`);
    }
  }
}

function handleSourceClick() {
  if (props.notChainFeed) {
    var source = item.value.sources[0];
    $store.dispatch('confirm', `Are you sure you want to block ${source.name} for this feed?`).then(() => {
      $emitter.$emit('excludeSource', {
        id: source.id,
        name: source.name
      });
    });
  }
}

function toggleStar() {
  item.value = {
    ...item.value,
    is_starred_by_my_team: !item.value.is_starred_by_my_team,
    starred_by_team_member: { email: $store.state.session.user.email }
  };
  $http.patch('/data/team_news_items/0', {
    is_starred_by_my_team: item.value.is_starred_by_my_team,
    news_item_id: item.value.id
  });
  if (!item.value.is_starred_by_my_team) {
    // if unstarred, remove the news item
    dispatch('VirtualList', 'unstar-news', item.value.id);
  }
}

function adminDelete() {
  if (!confirm('Are you sure you want to hide this story for every SigDev user?')) {
    return;
  }

  $http.patch(`/admin/news_items/${item.value.id}`, {
    is_hidden: true,
    news_item_id: item.value.id
  });
  item.value.hide = true;
  $root.flashMessage({
    type: 'success',
    message: 'News item deleted successfully.'
  });
}

function share() {
  const link = item.value.link;
  copyToClipboard(link);
}

function mouseEnter() {
  clearInterval(interval.value);
  interval.value = setTimeout(() => {
    hovering.value = true;
  }, 500);
}

function mouseLeave() {
  clearInterval(interval.value);
  hovering.value = false;
}

function dispatch(componentName, eventName, data) {
  let parent = $parent || $root;
  let name = parent.$options.name;

  while (parent && (!name || name !== componentName)) {
    parent = parent.$parent;
    if (parent) {
      name = parent.$options.name;
    }
  }

  if (parent) {
    parent.$emit.apply(parent, [eventName].concat(data));
  }
}
//GROUPED ITEMS
const toggleVisibility = id => $emitter.$emit('toggle:show-grouped-items', id);
const newsGroupOpened = id => {
  return props.showGroupedNewsItems.includes(id);
};
</script>
